// icon size
.icon {
    position: relative;
    width: $icon-size-base;
    height: $icon-size-base;


    &.icon-with-bg {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    >svg {
        width: $icon-size-base;
        height: $icon-size-base;
    }
}

@each $size,
$value in $icon-font-sizes {
    .icon-#{$size} {

        &.feather {
            width: $value * 0.8;
            height: $value * 0.8;
        }

        >svg {
            width: $value;
            height: $value;
        }
    }
}

// fill icon
.icon[class*="text-"]>svg [fill]:not([fill="none"]) {
    fill: currentColor !important;
}



// Icons - colors
.icon-dual {
    color: $gray-600;
    fill: rgba($gray-600, 0.12);
}

@each $color,
$value in $theme-colors {
    .icon-dual-#{$color} {
        color: $value;
        fill: rgba($value, 0.16);

        >svg {
            color: $value;
            fill: rgba($value, 0.16);
        }
    }

    .icon-fill-#{$color} {
        fill: #{$value};

        >svg {
            fill: #{$value};
        }
    }
}

// arrows icons
.icon-left-arrow,
.icon-right-arrow {

    @each $size,
    $value in $icon-font-sizes {
        &.icon-#{$size} {
            width: $value;
            height: $value;
        }
    }

    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
}

.icon-left-arrow {
    background-image: url("data:image/svg+xml,%3Csvg width='1em' height='1em' viewBox='0 0 16 16' class='bi bi-arrow-left' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' d='M5.854 4.646a.5.5 0 0 1 0 .708L3.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0z'/%3E%3Cpath fillRule='evenodd' d='M2.5 8a.5.5 0 0 1 .5-.5h10.5a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z'/%3E%3C/svg%3E");
}

.icon-right-arrow {
    background-image: url("data:image/svg+xml,%3Csvg width='1em' height='1em' viewBox='0 0 16 16' class='bi bi-arrow-right' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' d='M10.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L12.793 8l-2.647-2.646a.5.5 0 0 1 0-.708z'/%3E%3Cpath fillRule='evenodd' d='M2 8a.5.5 0 0 1 .5-.5H13a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8z'/%3E%3C/svg%3E");
}
